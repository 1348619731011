@mixin bodyTypography() {
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
	letter-spacing: normal;
	font-family:$pFont;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


@mixin bgImg($position, $size) {
	background: {
		position: $position;
		size:$size;
		repeat:no-repeat;
	}
}

@mixin overlay($color, $percentage) {
	position: relative;
	&:before {
		content:'';
		background-color:rgba($color, $percentage);
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}
}

@mixin gradientOverlay($deg, $color1, $color2) {
	background-blend-mode: multiply;
	background-image: linear-gradient(#{$deg}deg, $color1, $color2);
}

// list style mixin
@mixin list() {
  list-style-type:none;
  padding:0;
  margin:0;
}


@mixin loading() {
	&.loading {
		position:relative;
		min-height: 300px;
		&:before {
			content:'';
			position:absolute;
			width:100%;
			height:100%;
			z-index: 1;
			background: {
				image:url(../images/loading.gif);
				repeat:no-repeat;
				position:top;
			}
		}
	}
}

@mixin menuHoverStyle() {
	&:hover,
	&.active {
		&:before {
			width:calc(100% + 12px);
		}
		&:after {
			opacity: 1;
			bottom:-6px;
		}
	}
	&:before {
		transition:width 0.3s ease;
		content:'';
		width:0;
		height: 2px;
		position: absolute;
		bottom: -3px;
		left:50%;
		transform:translateX(-50%);
		background-color: $primary;
	}
	&:after {
		transition:bottom 0.3s ease, opacity 0.3s ease;
		opacity: 0;
		content:'';	
		position:absolute;
		left:50%;
		transform:translateX(-50%);
		bottom:-3px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 3px 3px 0 3px;
		border-color: $primary transparent transparent transparent;
	}
}

@mixin skewBG($deg) {

	transform:skew($deg);
		background-color: $lousBlack;
		position: relative;
		span {
			transform:skew(-#{$deg});
			display: inline-block;
			position: relative;
			z-index: 1;
		}
		&:before {
			content:'';
			position: absolute;
			background-color: $lousBlack;
			height: 100%;
			width:100%;
			transform: skew(-#{$deg * 2}) rotate(-2deg);
			top: -4px;
			left: -1px;
		}	

}