.block.basic-banner {
	position:relative;
	@include bgImg(center,cover);
	padding-top: 10vh;
	padding-bottom:calc(15vh + 20px);
	&:after {
		content: "";
		position: absolute;
		height: 15vh;
		width: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(hsla(0,0%,100%,0),#1b1a1f 90%);
	}

  .basic-banner--h1 {
    margin: 0;
  }

	.basic-banner--title {
		margin:0 0 0;
		color: white;
		font-family: $hFont;
		font-size: 27px;
		display: block;
    line-height: 1em;
		.basic-banner--title_inner {
			display: inline-block;
			padding:10px 10px 0px;
			@include skewBG(-5deg);
			.post-categories {
				@include list();
				a {
					text-decoration: none;
					color:$pColor;
				}
			}
		}
	}

	&.has-tagline-shortcode.has-title {
		.basic-banner--tagline {
			margin-top: -50px;
		}
	}

	.basic-banner--tagline {
		color: white;
		font-family: $hFont;
		font-size: 35px;
		display: block;
		margin: 0;
		line-height: 1em;
		font-weight: bold;
		html[lang='fr-CA'] & {
			white-space: normal;
		}
		span {
			padding:3px 15px 0;
			background-color: $lousBlack;
			@include skewBG(5deg);
			z-index: 1;
			transform:none;
			&:before {
				z-index: -1;
				transform: skew(4deg) rotate(0deg);
				left:5px;
				top: 0;
			}
		}
		span,
		.basic-banner--dynamic-logo {
			display: inline-block;
			vertical-align: bottom;
		}
		.basic-banner--dynamic-logo {
			max-width: 75px;
			img {
				display: block;
			}
		}
	}

	.basic-banner--sub-title {
		color: white;
		font-family: $pFont;
		font-size: 23px;
		display: block;
		font-weight: bold;
		.basic-banner--sub-title_inner {
			display: inline-block;
			padding:10px;
			@include skewBG(5deg);
			line-height: 1.3em;
			span {
				position: relative;
				z-index: 1;
			}
		}
	}


	.basic-banner--button-container{
		margin-left: auto;
		margin-top: 15px;
	}
	.basic-banner--button{
		font-size: 25px;
		font-weight: 700;
		padding: 15px 18px 13px;
		margin-left: auto;
	}

	// product banner styles
	&.is-product-banner {
		padding-bottom: 20px;
		.basic-banner--product_image {
			margin-top: 20px;
			position: relative;
			z-index: 2;
		}

	}


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.basic-banner--sub-title {
			font-size: 45px;
			//margin-top: -10px;
			font-size: 25px;

			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		.basic-banner--button-container{
			margin-top: 0px;
		}


		.basic-banner--tagline {
			.basic-banner--dynamic-logo {
				max-width: 175px;
			}
			span {
				padding:18px 15px 0;
			}
		}

		.basic-banner--title {
			font-size: 37px;
		}

		.basic-banner--tagline {
			font-size: 75px;
		}


	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.basic-banner--tagline {
			.basic-banner--dynamic-logo {
				max-width: 225px;
			}
		}

		.basic-banner--title {
			font-size: 47px;
		}

		.basic-banner--tagline {
			font-size: 90px;
		}

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.basic-banner--tagline {
			.basic-banner--dynamic-logo {
				max-width: 300px;
			}
		}

		.basic-banner--title {
			font-size: 67px;
		}

		.basic-banner--tagline {
			font-size: 116px;
		}

	}
}
