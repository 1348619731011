.single-product {


	.product-single--info {

		.product-single--info__image-col {
			padding-bottom:56.25%;
		}
		.product-single--info__content {
			color:white;
			padding-bottom: 40px;
			font-size: 22px;
			line-height: 1.4em;
			font-weight: 600;
		}

		.product-single--info__big-text {
			h2 {
				text-transform: none;
				color:white;
				font-weight: normal;
				margin:20px 0;
				line-height: 1em;
			}
			.product-single--info__big-text-one {}
			.product-single--info__big-text-two {}
		}

		.product-single--info__primary-content {}
		.product-single--info__secondary-content {}

	}

	.product-single--cooking_instructions {
		padding:40px 0;
	}
	.product-single--cooking_instructions__title {
		color:white;
		text-align: center;
		margin: 0 0 40px 0;
	}
	.product-single--cooking_instructions__description {
		color: white;
		font-size: 22px;
		line-height: 1.5em;
		font-weight: 600;
		max-width: 850px;
	}

	.product-single--cooking_instructions__accoridion-container {
		padding:40px 0;
	}

	.product-single--cooking_instructions__accordion-title {
		border-bottom:2px solid white;
		padding:20px 10px 20px 45px;
		text-transform: uppercase;
		font-size: 22px;
		font-weight: 600;
		color: white;
		display: block;
		cursor: pointer;
		position: relative;
		.product-single--cooking_instructions__accordion-icon {
		    max-width: 40px;
		    position: absolute;
		    left: 0;
		    top: 50%;
		    transform: translateY(-50%);
		}
		&:after {
			@include iconFont();
			content: "\";
			position:absolute;
			right:20px;
			top:50%;
			transform:translateY(-50%);
			transition:$transition;
		}
		&.active {
			&:after {
				transform:translateY(-50%) rotate(180deg);
			}
		}
	}
	.product-single--cooking_instructions__accordion-instructions {
		color:white;
		display: none;
		font-size: 19px;
		line-height: 1.4em;
		font-weight: 600;
		max-width: 925px;
		&.start-open {
			display: block;
		}
		.inner {
			padding:15px 0;

			ul,
			ol {
				li + li{
					margin-top: 15px;
				}
			}
		}
	}

	/**
	 * Ingredients
	 */
	.product-single--ingredients {
		padding:30px 0 50px;
	}

	.product-single--ingredients_title {
		font-size: 75px;
		color:white;
		text-transform: none;
		font-weight: normal;
		white-space: nowrap;
		&.desktop {
			display: none;
		}
		&.mobile {
			display: block;
			position:relative;
			z-index: 1;
			margin:0;
		}
	}

	.product-single--ingredients_image-col {
		position: relative;
		margin-top: 20px;
	}
	.product-single--ingredients_content-col {
		padding-bottom: 0px;
	}
	.product-single--ingredients_image {
		position: relative;
		padding-bottom: 56.25%;
		margin-top: -25px;
		background: {
			position: center;
			size:cover;
			repeat:no-repeat;
		}
	}

	.product-single--ingredients_features {
		@include list();
		margin-top: 60px;
		margin-bottom: 40px;
		li {
			color:white;
			text-transform: none;
			font-size: 35px;
			line-height: 1.2em;
			margin-bottom: 25px;
			&.small {
				padding-left: 8%;
			}
			&.large {
				padding-left: 16%;
			}
		}
	}

	.product-single--ingredients_btn {
		&.nutrition {
			padding-left: 8%;
		}
	}
	.custom-btn {
		color:white;
		font-size: 40px;
		margin-bottom: 20px;
		font-weight: bold;
		padding:20px 25px;
		letter-spacing: 0;
		&:hover {
			background-color: white!important;
			color:$lousBlack!important;
		}
	}




	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.product-single--info {
			.product-single--info__content {
				padding-bottom: 80px;
			}
		}

		.product-single--cooking_instructions {
			padding:90px 0;
		}


		.product-single--ingredients_content-col {
			padding-bottom: 50px;
		}

		.product-single--info {
			.product-single--info__image-col {
				margin-top: 80px;
				padding-bottom: 0;
			}
			.product-single--info__big-text {
				//direction: rtl;
				h2 {
					font-size: 130px;
					white-space: nowrap;
				}
				&.product-single--info__big-text-one {
					//padding-right: 10vw;
				}
			}
			.product-single--info__primary-content,
			.product-single--info__secondary-content {
				padding:0 20px 0 50px;
			}
			.product-single--info__secondary-content {
				padding-top: 30px;
			}
		}

		.product-single--cooking_instructions__title {
			color:white;
			text-align: right;
			font-size: 80px;
		}

		.product-single--cooking_instructions__accordion-instructions {
			font-size: 22px;
		}


		/**
		 * Ingredients
		 */

		.product-single--ingredients_image-col {
			margin-top: 80px;
		}
		.product-single--ingredients_title {
			font-size: 160px;
			margin:0 0 15px 0;
			&.desktop {
				display: block;
			}
			&.mobile {
				display: none;
			}
		}

		.product-single--ingredients_image {
			position: absolute;
			top: 0;
			left:0;
			width:100%;
			height: 100%;
			padding-bottom: 0;
		}

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		.product-single--info {
			.product-single--info__big-text {
				h2 {
					font-size: 160px;
				}
			}
		}

		.product-single--cooking_instructions__title {
			color:white;
			text-align: right;
			font-size: 120px;
		}

	}
}


.product-banner{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
	background-color: $lousBlack;
	position: fixed;
	width: 100%;

	.hide-header &{
		padding-top: 30px;
	}

	br{
		display: none;
	}
	.product-banner--title{
		margin: 0;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 1;
		color: #FFFFFF;
	}

	.product-banner--title,
	.product-banner--button-container{
		padding: 0 15px;
	}
	.product-banner--button{
		font-weight: 700;
		font-size: 18px;
		padding: 10px 18px 6px;
	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		justify-content: center;


		.hide-header &{
			padding-top: 15px;
		}

		.product-banner--title{
			font-size: 40px;
		}
		.product-banner--button{
			font-size: 25px;
			padding: 15px 18px 13px;
		}
	}
}
