.category-filter {
	// desktop filter
	&.desktop {
		background-color: $lousBlack;
		border: 3px solid white;
		@include list();
		display: none;
		flex-wrap: nowrap;
		justify-content: space-around;
		padding: 10px 10px;
		align-items:center;
		transform:skew(-2deg);
		margin-bottom:50px;
		li {
			transform:skew(2deg);
			text-align: center;
			text-decoration: none;
			flex: 1 1 0;
			&.filter-title {
				color: $primary;
				font-family: "Nexa Rust";
				display: none;
			}
			a {
				padding: 5px;
				color: white;
				text-transform: uppercase;
				font-family: "Nexa Rust";
				text-decoration: none;
				transition: $transition;
				display: block;
				margin: 0 5px;
				line-height: 1.1em;
				font-size: 14px;
			}

			&:hover,
			&.active {
				a {
					background-color: white;
					color: $primary;
				}
			}
		}
	}

	//mobile filter (dropdown)
	&.mobile {
		display: block;
		margin-bottom: 20px;
		.select-wrap {
			background-color: $lousBlack;
			border:3px solid white;
			position: relative;
			&:after {
				content: "\";
				@include iconFont();
				position: absolute;
				right:20px;
				top:50%;
				transform:translateY(-50%);
				color:$primary;
				font-size: 20px;
				z-index: 1;
			}
			select {
				position: relative;
				z-index: 2;
				appearance:none;
				width: 100%;
				display: block;
				background-color: transparent;
				border:none;
				color: white;
				font-family: $pFont;
				font-weight: 600;
				height: 50px;
				padding:0 20px;
				font-size: 18px;
				option {
					color:$lousBlack;
				}
			}
		}
	}
}