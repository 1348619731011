.menus.mobile {
	position: fixed;
	height: calc(100% - #{$header-mobile-height} - 2px);
	width: 100%;
	z-index: 100;
	display: block;
	background-color: transparent;
	top: $header-mobile-height - 1; // height of mobile header
	transform:translateX(100%);
	transition: $transition;
	.mobile-menu-inner {
		background-color: $lousBlack;
		height: 100%;
		padding: 30px 20px 80px;
		margin:0 auto;
		overflow-y: auto;

		.container {
			max-width: none;
			padding:0;
			.row {
				margin-left:0;
				margin-right: 0;
				.col-12 {
					padding:0;
				}
			}
		}
	}
	.menu-open & {
		transform: translateX(0);
	}


	.mobile-menu-get-a-quote-button-wrap {
		width: 100%;
		display: block;
		margin-top:20px;
		.btn {
			padding:12px 20px;
		}
	}


	ul.nav {
		@include list();
		max-width: 767px;
		margin:0 auto;
		& > li {
			display: block;
			margin-bottom: 30px;
			&.menu-item-has-children {
				position: relative;
				&:after {
					@include iconFont();
					content: "\";
					position: absolute;
					right:10px;
					top:4px;
					color:$pColor;
					font-size: 22px;
					transition:$transition;
				}
				&.sub-menu-open {
					&:after {
						transform:rotate(180deg);
					}
				}
				& > a {
					display: block;
					position: relative;
					z-index: 2;
				}
			}
			&.active {
				& > a {
					color:$primary;
				}
			}
			& > a {
				color:white;
				font-family: $pFont;
				font-weight: bold;
				font-size: 28px;
				text-transform: uppercase;
				text-decoration: none;
				transition:$transition;
				&.active,
				&:hover {
					color:$primary;
				}
			}
		}
		
		.sub-menu {
			display: none;
		}

		.sub-menu {
			@include list();
			margin-left: -10px;
			li {
				margin-bottom: 10px;
				width: 100%;
				&.active {
					a {
						color:$primary;
						background-color: $pColor;
					}
				}
				&:first-child {
					padding-top: 20px;
				}
				&:last-child {
					border-bottom:1px solid rgba(#D8D8D8, 0.4);
					padding-bottom: 20px;
				}
			}
			a {
				padding: 5px 10px;
				font-size: 20px;
				text-transform: uppercase;
				font-family: $pFont;
				transition:$transition;
				display: inline-block;
				color:$pColor;
				text-decoration: none;
				font-weight: bold;
				&:hover {
					color:$primary;
					background-color: $pColor;
				}
			}
			
		}

		
	}
	

	

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	.admin-bar & {
		top: calc(32px + #{$header-mobile-height});
	}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: none;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
