.menu-trigger-container {
	height: 100%;
	top: 50%;
	transform:translateY(-50%);
	width: 50px;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	transition:background-color 0.3s ease;
	right: 20px;
	.menu-open & {
		
	}
	.shrink & {

	}
	.menu-trigger {
	    display: block;
	    position: absolute;
	    height: auto;
	    z-index: 4;
	    transition: .3s;
	    top: 50%;
	    width: 100%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    padding-bottom: 7px;
		.bar {
			left: 0px;
			background-color: white;
			height: 4px;
			display: block;
			transition: right 0.3s ease, transform 0.3s ease;
			transform: rotate(0deg);
			transform-origin: center center;
			width: 40px;
			border-radius:2px;
			&.bar-1 {
				margin-bottom:7px;
			}
			&.bar-2 {
				margin-bottom:7px;
				width:40px;
			}
		}
		.menu-open & {
			.bar-3,
			.bar-2,
			.bar-1 {
				margin: -4px 0 0 0px;
				background-color:$primary!important;
			}
			.bar-3 {
				transform: rotate(45deg);
			}
			.bar-2 {
				opacity: 0;
			}
			.bar-1 {
				margin-bottom: 0;
				transform: rotate(-45deg);
			}
		}
	}
}
