.error404 {

	.page-not-found-container {
		padding:12vh 0;
		h1 {
			text-align: center;
			margin: 0;
			color:white;
			font-size: 25vh;
			line-height: 0.8em;
		}
		h5 {
			text-align: center;
			font-family: $pFont;
		}


		.error404-link-wrap {
			text-align: center;
			margin-top: 40px;
		}

		.error404-disclaimer-text {
			text-align: center;
			color:white;
			margin-top: 25px;
    		font-size: 12px;
    		a {
				color:$primary;
				transition:$transition;
				&:hover {
					color:$secondary;
				}
    		}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.page-not-found-container {
			
		}

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
