.numeric-pagination {
	text-align: center;
	font-size: 15px;
	margin: 40px 0 0px;
	padding-top: 40px;
	border-top:1px solid $grey-med;
	a {
		color: $grey-med;
		text-decoration: none;

	}
	i {
		vertical-align: -3px;
	}

	.page-numbers {
		width: 40px;
		position: relative;
		display: inline-block;
		line-height: 40px;
		border-radius:3px;
		transition: $transition;
		font-weight: bold;
		font-size:21px;
		&.current {	
			background-color: $grey-med;
			color:white;
		}
		&:hover {
			&:not(.current) {
				background-color: darken($grey-med, 10%);
			}
		}
		&.prev {
			float:left;
		}
		&.next {
			float: right;
		}
		&.next, &.prev {
			font-family: $pFont;
			width: auto;
			transition: $transition;
			display:inline-block;
			font-size:16px;
			font-weight: 500;
			.text {
				margin: 0 10px;
			}
			&:hover {
				background-color: transparent;
				color: $secondary;
			}
		}
	}
	

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}