.block.breadcrumb {
	margin:0;
	padding:20px 0;
	.breadcrumbs {
		font-size:12px;
		text-transform: uppercase;
		letter-spacing:0.125em;
		a {
			text-decoration: none;
			color:$primary;
			transition:color 0.3s ease;
			&:hover {
				color: $secondary;
			}
		}
		i {
			vertical-align: -2px;
			color:$grey-med;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}