.socket {
	background-color: $lousBlack;
	padding: 23px 0;
	text-align: center;
	color: $grey-med;
	font-family: $pFont;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.075em;
	.copyright {
		color: white;
		font-family: 12px;
		opacity: 0.8;
	}
	p a,
	a {
		.wrap & {
			opacity: 0.8;
			color: white;
			text-decoration: none;
			transition: $transition;
			&:hover {
				color: $primary;
			}
		}
	}


	.attribution {
		i {
			margin-left: 6px;
		}
	}
	

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
			
		padding: 7px 0;
		.copyright {
			text-align: left;
		}
		.footer-content--attribution {
			text-align: right;
		}
		
		
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
