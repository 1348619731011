.article-container {
	position: relative;
	padding-left: 110px;
	margin-bottom: 20px;
	.is-meal-idea & {
		padding-left: 0px;
		&:before {
			display: none;
		}
		time {
			display: none;
		}
	}
	&:before {
		left: 45px;
		top: 110px;
		height: calc(100% - 110px);
		content: "";
		position: absolute;
		width: 1px;
		background-color: #707070;
	}
	time {
		position: relative;
		width: 90px;
		height: 90px;
		border: 2px solid $secondary;
		display: block;
		border-radius: 50%;
		position: absolute;
		left: 0;
		top: 0;
		.inner {
			text-align: center;
			font-weight: 700;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			line-height: 1em;
			.day {
				font-size: 30px;
				color:$pColor;
			}
			.month {
				margin-top: 2px;
				font-size: 18px;
				display: block;
				color: $primary;
			}
		}
	}

	img {
		width: 100%;
	}

	.entry-title {
		margin: 40px 0 0px;
		padding: 0 0 20px;
		font-weight: 600;
		font-size: 24px;
		position: relative;
		a {
			color: $primary;
			text-decoration: none;
			text-transform: uppercase;
			transition: $transition;
			&:hover {
				color: $secondary;
			}
		}
	}

	.description {
		font-weight: 500;
		color: $pColor;
	}

	.btn-wrap {
		margin-top: 20px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		margin-bottom: 110px;

		.entry-title {
			font-size: 54px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
