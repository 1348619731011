@import url("//hello.myfonts.net/count/3ada0b");

@font-face {font-family: 'Organika-Sans';src: url('../fonts/3ADA0B_0_0.eot');src: url('../fonts/3ADA0B_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3ADA0B_0_0.woff2') format('woff2'),url('../fonts/3ADA0B_0_0.woff') format('woff'),url('../fonts/3ADA0B_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Organika-Script';src: url('../fonts/3ADA0B_1_0.eot');src: url('../fonts/3ADA0B_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3ADA0B_1_0.woff2') format('woff2'),url('../fonts/3ADA0B_1_0.woff') format('woff'),url('../fonts/3ADA0B_1_0.ttf') format('truetype');}


/**
 * Nexa Rust Sans W00 Black 01
 * @link https://www.onlinewebfonts.com/download/9f183bf61a6dcd84179d7676bbfbbbbf
 */
@font-face {
	font-family: "Nexa Rust";
	src: url("../fonts/nexa-rust.eot"); /* IE9*/
	src: url("../fonts/nexa-rust.eot?#iefix") format("embedded-opentype"),
		url("../fonts/nexa-rust.woff2") format("woff2"),
		url("../fonts/nexa-rust.woff") format("woff"),
		url("../fonts/nexa-rust.ttf") format("truetype"),
		url("../fonts/nexa-rust.svg#Nexa Rust Sans W00 Black 01") format("svg");
}


