.post-list {

	.category-filter {
		&.desktop {
			display: none;
		}
		&.mobile {
			display: block;
		}
	}

	.load-more-button-container {
		text-align: center;
		margin-bottom: 100px;
		.btn {
			font-size: 30px;
			font-weight: bold;
			letter-spacing: normal;
		}
	}

	
	#loading-wrap {
		@include loading();
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		
		.category-filter {
			margin-top: 40px;
			&.desktop {
				display: flex;
				display: block;
				li {
					display: inline-block;
					flex:none;
					padding:5px 10px;
				}
			}
			&.mobile {
				display: none;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}