.product-none--item {
	text-align: center;
	h4 {
		font-size: 18px;
		font-family: 'Nexa Rust';
		color:$charcoal;
		text-transform: none;
		background-color: white;
		padding:10px;
	}
}