.block.contact {
	position: relative;
	padding:0;
	margin:0 0 100px 0;
	
	.contact--form-wrap {
	}

	.contact--form-title {
		margin: 0 0 0px;
	}

	.contact--form {
		p {
			max-width:450px;
			color:$grey-med;
		}
	}

	.contact--image {
		background: {
			size:cover;
			position:left center;
		}	
		width:100%;
		padding-bottom:56.25%;
		margin-bottom: 40px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	
		

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		margin:100px 0;
		.contact--image {
			position: absolute;
			top:0;
			right:0;
			width:45%;
			height: 100%;
			padding-bottom: 0;
			margin-bottom: 0;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top;
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}