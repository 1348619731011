.block.curator {
	padding-top:50px;
	padding-bottom: 0;	
	overflow-x:hidden;
	.curator--container {
		padding:0;
	}
	.curator--title {
		font-weight: 500;
		text-align: center;
		margin:0 0 5px 0;
		color: white;
		text-transform: none;
		font-size: 50px;
	}

	.curator--description {
		text-align: center;
		color: white;
		font-size: 20px;
		font-weight: bold;
		margin:20px 0;
	}

	.curator--row {
		margin-top:40px;
	}
	
	.crt-widget.crt-widget-branded {
		padding-bottom: 0;
	}

	.crt-widget.crt-widget-branded .crt-logo {
		font-family: $hFont;
		right:40px;
		color:white;
		text-transform: uppercase;
		font-size: 15px;
    	max-width: 114px;
    	bottom: 5px;
    	text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
	}

	.crt-feed {
		//margin:-5px;
	}

	.crt-widget-grid .crt-grid-post {
		//padding:5px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.curator--title {
			font-size: 7vw;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.curator--title {
			font-size: 145px;
		}
	
	}
}