.block.basic-content {

	&.basic-content--no-top-padding {
		padding-top:0;
	}
	&.basic-content--no-bottom-padding {
		padding-bottom:0;
	}
	&.basic-content--bg-grey {
		background-color:white;
	}

	h1,h2,h3,h4,h5,h6 {
		font-weight: 500;
		margin:20px 0 0 0;
		color:white;

    &.heading-large {
      font-size: 80px;
      margin-bottom: 60px;
      margin-top: 40px;
    }
    &.heading-medium {
      font-size: 40px;
      text-transform: none;
      font-family: $pFont;
      margin-bottom: 24px;
      margin-top: 60px;
    }
    &.heading-small {
      font-family: $pFont;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 24px;
      margin-top: 40px;
    }
	}
	p,ul,ol {
		color:white;
		a {
			color:$primary;
			transition:$transition;
			&:hover {
				color:$secondary;
			}
		}
	}

	p {
		strong {
			font-weight: 600;
		}
	}
	blockquote p {
		color:$primary;
	}

	.basic-content--column {
		margin:20px 0;
	}

	.basic-content--content {

		// .entry-content-asset {
		// 	position:relative;
		// 	padding-bottom:56.25%;
		// 	iframe {
		// 		position:absolute;
		// 		width:100%;
		// 		height:100%;
		// 		left:0;
		// 		top:0;
		// 	}
		// }
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
