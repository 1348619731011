fieldset {
    border-color: #fafafa;
    legend {
        font-weight: 600;
    }
}
.gform_wrapper,
form {
    margin-bottom: 10px;
    .required {
        color: $primary;
        margin-left: 5px;
    }

    .gfield_required,
    label.gform-field-label,
    label {
        font-weight: 500;
        color: white;
        font-size: 20px;
        line-height: 1.75em;
        margin-bottom: 8px;
    }
    .ginput_container_fileupload {
        color: white;
        font-family: montserrat, sans-serif;

        input {
            color: white;
            font-family: montserrat, sans-serif;
        }
    }

    .gfield{
      margin-bottom: 24px !important;
    }
    /**
     * radio and check marks
     */
    .gf_radio_field,
    .gf_checkbox_field {
        margin-bottom: 40px !important;

        .gform-field-label--type-inline {
          font-family: $pFont;
          font-weight: 400;
          color: white;
          font-size: 24px;
          line-height: 1.75em;
          margin-left: 5px;
        }
        .gfield_label {
            font-size: 28px;
            font-weight: bold;
            color: white;
            margin-bottom: 15px;
        }
        .checkmark {
          top: 10px !important;
        }
        .gfield_radio,
        .gfield_checkbox {
            margin-top: 15px;
            li {
                margin-bottom: 0 !important;
            }
            label {
                color: $grey-med;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }

    /**
     * Validation Styles
     */

    div.validation_error {
        background-color: $error;
        border: none;
        color: white;
        font-family: $hFont;
        font-size: 17px;
        display: none !important;
    }
    &.gform_validation_error {
        .gfield.gfield_error {
            border: none;
            background-color: $error;
            padding: 0;
            margin: 0;
            .ginput_container {
                padding: 0 !important;
                margin: 0 !important;
                input,
                select,
                textarea {
                    //padding-:0!important;
                    margin: 0 !important;
                }
            }
            div.validation_message.gfield_description {
                color: white;
                margin: 0;
                padding: 5px 20px;
                font-family: $hFont;
                font-weight: normal;
                font-size: 17px;
                letter-spacing: 1px;
            }
        }
    }
    /**
     *  Required
     */

    .gfield_contains_required {
        .ginput_container_email,
        .ginput_container_select,
        .ginput_container_text,
        .ginput_container_phone {
            position: relative;
            &:after {
                content: "*";
                position: absolute;
                color: $primary;
                right: 22px;
                top: 52px;
                transform: translateY(-50%);
                font-size: 40px;
            }
        }
    }

    .gfield {
        margin-top: 0 !important;
        margin-bottom: 15px;
        .ginput_container {
            margin-top: 0 !important;
        }
    }

    input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
    select,
    textarea {
        width: 100%;
        font-size: 16px;
        background: #000000;
        border: 1px solid #ffffff;
        width: 100%;
        max-width: none;
        display: inline-block;
        height: 72px;
        padding: 0 40px 0 20px;
        font-size: 25px;
        font-weight: 600;
        transition: $transition;
        font-family: $pFont;
        vertical-align: top;
        color: white;
        margin-top: 7px;
        &:focus {
            border-color: $primary;
            outline: none;
        }
    }

    button[type="submit"] {
        width: 100%;
        max-width: none;
        background-color: $orange;
        color: white;
        font-family: $hFont;
        padding: 5px 10px 0;
        display: inline-block;
        border: none;
        cursor: pointer;
        transition: $transition;
        margin: 10px 0 0 0;
        height: 72px;
        font-size: 39px;
        font-weight: bold;
        vertical-align: top;
        &:hover {
            background-color: lighten($primary, 10%);
        }
    }

    input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
    select {
        height: 78px;
        padding: 0px 20px;
        margin-bottom: 5px;
    }

    textarea {
        padding: 20px 20px 20px;
    }

    .gform_footer {
        margin: 0;
        padding: 0;
        button {
            width: auto;
            padding: 5px 25px 0;
        }
    }
}

/**
 * Mailchimp styles
 */

#mce-responses {
    text-align: center;
    .response {
        color: white;
        margin: 50px 0 0px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 30px;
    }
}

/**
 * Custom Checkbox
 */
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: $navy;
            &:after {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 28px;
        width: 28px;
        background-color: white;
        border: 3px solid $navy;
        transition: background-color 0.3s ease;
        border-radius: 5px;
    }
    &:hover input ~ .checkmark {
        // background-color: $grey-med;
    }
    .label {
        font-size: 14px;
        color: $grey-dark;
        font-weight: 500;
    }

    /* =============================================================================
    Extra Small +
    ========================================================================== */
    @include media-breakpoint-up(xs) {
    }

    /* =============================================================================
    Small +
    ========================================================================== */
    @include media-breakpoint-up(sm) {
    }

    /* =============================================================================
    Medium +
    ========================================================================== */
    @include media-breakpoint-up(md) {
    }

    /* =============================================================================
    Large +
    ========================================================================== */
    @include media-breakpoint-up(lg) {
    }

    /* =============================================================================
    Extra Large +
    ========================================================================== */
    @include media-breakpoint-up(xl) {
        .label {
            font-size: 16px;
        }
    }
}
