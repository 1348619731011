blockquote {
	background: transparent;
	margin: 20px auto;
	font-family: $hFont;
	padding: 0;
	text-align: center;
	font-size: 35px;
	font-weight: normal;
	color:$primary;
	line-height: 38px;
	position: relative;
	overflow: hidden;
	border-top:3px solid $primary;
	border-bottom:3px solid $primary;
	padding:30px 0;
	p {
		color:$primary;
	}
	cite {
		display: block;
		font-size: 18px;
		margin-top: 17px;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	&.is-large.wp-block-quote {
		margin: 0 0 20px;
		padding: 0;

		cite {
			text-align: center;
		}
	}

	&.wp-block-pullquote {
		border-top: 2px solid $primary;
		border-bottom: 2px solid $primary;
		padding: 20px 0;
	}

	

}