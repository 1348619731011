.utility-menu {
	width:100%;
	text-align: right;
	.shrink & {
		top: -20px;
	}
	transition:$transition;
	position: absolute;
	top:14px;
	right: 20px;
	font-weight: 500;
	font-size: 16px;
	font-family: $hFont;
	a {
		color:$primary;
		text-decoration: none;
		transition:$transition;
		&:hover{
			color:$grey-med;
		}
	}

	display: none;
	
	p {
		margin:0;
		a {
			.wrap & {
				text-decoration: none;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		display: block;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}