/* Customize the label (the field.radio) */

.gf_checkbox_field {
    margin-top: 30px!important;
    margin-bottom: 30px!important;
}
.gfield,
.field {
    &.radio,
    &.checkbox,
    &.gf_checkbox_field .gfield_checkbox li,
    &.gf_radio_field .gfield_radio li {
        display: block;
        position: relative;
        padding-left: 29px!important;
        margin-bottom: 17px;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        label {
            cursor: pointer;
            font-size: 20px;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 3px;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: $lousBlack;
            border:1px solid #979797;
            transition:$transition;
            &:after {
                content: "";
                position: absolute;
                display: none;
                transition:$transition;
            }
        }
    }
    &.radio,
    &.gf_radio_field .gfield_radio li {
        input {
            &:checked ~ .checkmark {
                background-color: transparent;
                &:after {
                    display: block;
                }
            }
        }
        label {
             &:hover {
                input ~ .checkmark {
                    //background-color: #979797;
                }
            }
        }
        .checkmark {
            border-radius: 50%;
            &:after {
                top: 50%;
                left: 50%;
                width: 23px;
                height: 23px;
                border-radius: 50%;
                background-color: $primary;
                transform: translate(-50%,-50%);
            }
        }
    }
    &.checkbox,
    &.gf_checkbox_field .gfield_checkbox li {
        label {
             &:hover {
                input ~ .checkmark {
                    //background-color: $primary;
                }
            }
        }
        input {
            &:checked ~ .checkmark {
                background-color: $primary;
                &:after {
                    display: block;
                }
            }
        }
        .checkmark:after {
            left: 6px;
            top: 2px;
            width: 9px;
            height: 15px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &.gf_radio_field .gfield_radio li {
        .checkmark {
            top: 8px;
        }
    }
    &.gf_radio_field .gfield_radio li {
        .checkmark {
            top: 5px;
        }
    }

}
