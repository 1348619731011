ul.share-post {
	width: auto;
	@include list();
	padding: 0px;;
	display: table;
	margin-top:15px;
	li {
		vertical-align: middle;
		margin-right: 20px;
		display: inline-block;
		&:last-child {
			margin-right: 0;
		}
		a {
			font-size: 20px;
			text-decoration: none;
			transition: $transition;
			color: $primary;
			&:hover {
				color: $secondary;
			}
		}
	}
}