.block.faq {
	position: relative;
	padding-top: 100px;

	.faq--divider {}
	.faq--content {}
	.faq--item {
		
		padding-bottom:25px;
		margin-bottom:25px;
		border-bottom:1px solid $grey-med;
		
	}
	.faq--question {
		font-family: $pFont;
		font-size: 22px;
		line-height: 28px;
		font-weight: bold;
		position: relative;
		margin:0 0;
		cursor: pointer;
		padding-right: 40px;
		text-transform: none;
		&.active {
			&:after {
				transform:rotate(180deg);
			}
		}
		&:after {
			@include iconFont;
			content: "\";
			transform:rotate(0deg);
			position:absolute;
			top:8px;
			right:0px;
			transition:$transition;
			font-size: 16px;
			color:$primary;
		}
	}
	.faq--answer {
		display: none;
		padding:20px 0;
		font-size: 18px;
		line-height: 1.5em;
		color: white;
		font-family: $pFont;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}