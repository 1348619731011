.search-results {
	article {
		overflow:hidden;
		margin:30px 0;
		padding:20px 20px 20px 60px;
		position:relative;
		&:nth-child(even) {
			background-color:#f2f2f2;
		}
		.entry-title {
			margin:0 0 10px;
			a {
				color:$primary;
				text-decoration: none;
				transition:all 0.3s ease;
				&:hover {
					color:$secondary;
				}
			}
		}
		.entry-summary {
			font-size:18px;
			font-weight: 300;
		    margin-top: 20px;
		    border-left: 4px solid $grey-med;
		    padding: 5px 0 5px 10px;
		}
	}

	.results-count {
		font-style: italic;
		display: block;
		margin-bottom:0px;
		padding-bottom:30px;
		border-bottom:1px solid #f2f2f2;
	}

	.result-number {
	    font-size: 35px;
	    opacity: .05;
	    position: absolute;
	    top: 26px;
	    left:10px;
	    font-weight: 900;
	}
	

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
