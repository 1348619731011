.block.home-banner {
	padding: 0;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		height: 15vh;
		width: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(hsla(0,0%,100%,0),#1b1a1f 90%);
	}

	.home-banner--slide {
		@include bgImg(center, cover);
	}

	.home-banner--slide_content {
		min-height: 85vh;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.home-banner--slide_inner {
	}

	.home-banner--slide_title {
		display: block;
		h1 {
			font-size: 44px;
		}

		.basic-banner--tagline_inner {
			display: inline-block;
			padding:10px 10px 0px;
			@include skewBG(5deg);
			&.empty {
				display: none;
			}
		}

		&.home-banner--slide_tagline_1 {
			h1 {
				span:not(:empty) {
					padding: 0px 15px 0px 15px;
				}
			}
			.home-banner--dynamic-logo {
				max-width: 110px;
				position: relative;
			}
		}

		&.home-banner--slide_tagline_2 {
			h1 {
				span:not(:empty) {
					padding: 5px 15px 0px 15px;
				}
			}

			.home-banner--dynamic-logo {
				margin-top:-45px;
				max-width: 93px;
				position: relative;
			}
		}

		h1 {
			display: inline-block;
			color: white;
			margin: 0;
			span {
				display: inline-block;
				background-color: $lousBlack;
				position: relative;
				z-index: 2;
			}
			.home-banner--dynamic-logo {
				display: inline-block;
				vertical-align: bottom;
				margin-left:-2px;
			}
		}
	}

	.home-banner--slide_description {
		background-color: $lousBlack;
		padding: 15px;
		color: white;
		font-weight: bold;
		font-size: 16px;
		line-height: 1.3em;
		max-width: 750px;
		margin-top: -1px;
	}

	.slick-dots {
	    width: 100%;
	    position: absolute;
	    bottom: 40px;
	    z-index: 5;
	    li {
	    	margin-right: 15px;
		    .slick-button {
		    	width:16px;
		    	height: 16px;
		    	background-color: white;
		    	opacity: 0.5;
		    	border:2px solid transparent;
		    }
		    &:hover:not(.slick-active) {
		    	.slick-button {
		    		opacity: 0.75;
		    	}
		    }
		    &.slick-active {
			    .slick-button {
			    	background-color: transparent;
			    	border-color:white;
			    	opacity: 1;
			    }
			}
		}
	}

  .slick-autoplay-toggle-button {
    right: calc(50% - 16px);

    span {
      width: 100%;
      height: 100%;
    }
  }

  .slick-autoplay-toggle-button,
  .slick-arrow{
    background: none;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    z-index: 10;
    position: absolute;
    bottom: 0px;
    left: auto;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    transition: all .4s ease;

    .slick-sr-only {
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      width: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
    }

    &:hover,
    &:focus {
      scale: 1.2;
      opacity: 0.8;
    }

    .slick-pause-icon {
      background: url(../images/slick-pause.svg) no-repeat;
      background-size: cover;
    }

    .slick-play-icon {
      background: url(../images/slick-play.svg) no-repeat;
      background-size: cover;
    }

    &.slick-prev {
      background: url(../images/slick-arrow-prev.svg) no-repeat;
      background-size: cover;
      right: calc(50% + 28px);
    }
    &.slick-next {
      background: url(../images/slick-arrow-next.svg) no-repeat;
      background-size: cover;
      right: calc(50% - 60px);
    }
  }

	.home-banner--slide_link {
		text-align: right;
		padding-right: 30px;
		a {
		    font-size: 40px;
		    font-weight: bold;
		    letter-spacing: -0.025em;
		    position: relative;
		    z-index: 2;
		    margin-top: -5px;
		    padding: 20px 30px;
		    &:hover {
		    	background-color: $primary;
		    	color:white;
		    }
		}
	}

	/**
	 * Floating Coupon box
	 */

	.home-banner--coupon-box {
		position: fixed;
		bottom:0px;
		right:0;
		background-color: $lousBlack;
		z-index: 10;
		padding:8px 10px;
		display: flex;
		flex-wrap:nowrap;
		align-items:center;
		width: 100%;
		justify-content:center;
		div {
			display: inline-block;
			color:white;
			font-family: $pFont;
			font-weight: bold;
			vertical-align: middle;
			&.home-banner--discount {
				font-family: $hFont;
				font-size: 35px;
				vertical-align: -8px;
				sup {
					font-size: 65%;
    				top: -0.35em;
				}
			}
			&.home-banner--description {
				max-width: 120px;
				padding-left: 10px;
				line-height: 1.2em;
			    font-size: 13px;
			    letter-spacing: 0.0250em;
			}
			&.home-banner--link {
				.btn {
					font-size: 25px;
					font-weight: bold;
					padding: 10px 18px 8px;
				}
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {


	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

		.home-banner--coupon-box {
			width:auto;
		}
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.home-banner--slide_title {
			h1 {
				font-size: 120px;
			}
			&.home-banner--slide_tagline_1 {
				h1 {
					span:not(:empty) {
						padding: 25px 15px 0px 15px;
					}
				}
				.home-banner--dynamic-logo {
					//position: absolute;
					max-width: 275px;
					bottom:0;
					width:100%;
				}
			}
			&.home-banner--slide_tagline_2 {
				.home-banner--dynamic-logo {
					max-width:210px;
				}
			}
		}

		.home-banner--slide_description {
			font-size: 24px;
		}

		.home-banner--coupon-box {
			display: block;
			bottom:30px;
			width:auto;
			&.home-banner--link {
				.btn {
					font-size: 31px;
				}
			}
		}

    .slick-autoplay-toggle-button {
      right: 84px;
    }
    .slick-autoplay-toggle-button,
    .slick-arrow{
      bottom: 39px;


      &.slick-prev {
        right: 128px;
      }
      &.slick-next {
        right: 40px;
      }
    }
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
