/**	
 * Floating Coupon box
 */

.floating-coupon {
	position: fixed;
	bottom:0px;
	right:0;
	background-color: $lousBlack;
	z-index: 10;
	padding:8px 10px;
	display: flex;
	flex-wrap:nowrap;
	align-items:center;
	width: 100%;
	justify-content:center;

	.floating-coupon--close {
		position: absolute;
	    color: white;
	    font-size: 19px;
	    background-color: $lousBlack;
	    width: 24px;
	    height: 24px;
	    line-height: 24px;
	    border-radius: 50%;
	    top: -11px;
	    left: -8px;
	    text-align: center;
	    transition:$transition;
	    cursor: pointer;
	    &:hover {
	    	background-color:$primary;
	    }
	}
	.floating-coupon--element {
		display: inline-block;
		color:white;
		font-family: $pFont;
		font-weight: bold;
		vertical-align: middle;
		&.floating-coupon--discount {
			font-family: $hFont;
			font-size: 35px;
			vertical-align: -8px;
			sup {
				font-size: 65%;
				top: -0.35em;
			}
		}
		&.floating-coupon--description {
			max-width: 120px;
			padding-left: 10px;
			line-height: 1.2em;
		    font-size: 13px;
		    letter-spacing: 0.0250em;
		}
		&.floating-coupon--link {
			.btn {
				font-size: 25px;
				font-weight: bold;
				padding: 10px 18px 8px;
			}
		}
	}
	


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {


	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
		
		width:auto;
		
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
			
		display: block;
		bottom:30px;
		&.floating-coupon--link {
			.btn {
				font-size: 31px;
			}
		}
	
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
