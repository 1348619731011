// Colours
$orange: #E55204;
$brown: #903516;
$pink: #AB1B2D;
$green: #799A05;
$red: #EC1D23;
$navy: #0f0607;
$yellow: #ff9f1c;
$charcoal: #1b1a1f;

$grey-dark: #333333;
$grey-med: #888888;
$grey-light: #E0E0E0;
$off-white: #F9F9F9;

$lousBlack: #0f0607;

$error: #d10000;

$primary: $orange;
$secondary: $grey-med;

$pColor: white;
$hColor: $navy;

// Fonts
$bebas: bebas-neue-by-fontfabric, sans-serif;
$montserrat: montserrat, sans-serif;

$hFont: $bebas;
$pFont: $montserrat;

// Other
$transition: all 0.3s ease;

$header-mobile-height: 60px;
$header-desktop-height: 91px;
$header-shrink-height: 60px;
$product-nav-height: 56px;

$desktop-logo-width: 203px;