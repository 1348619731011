.site-header {
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100%;
	transition:transform 0.3s ease;
	.admin-bar & {
		top: 32px;
	}
	.menu-open & {
		.site-info {
			
		}
	}
	
	@include media-breakpoint-down(md) {
		.hide-header:not(.top):not(.menu-open) & {
			transform: translateY(-76px);
		}
	}

	.site-info {
		transition: $transition;
		position:relative;
		z-index: 1;
		background-color:transparent;
	background-color: $lousBlack;

		.shrink & {
			
			.menu-trigger .bar {
				//background-color:black;
			}
		}
		> .container-fluid,
		> .container {
			position: relative;
		}
		> .container  > .row {
			height: $header-mobile-height;
		}
		> .container-fluid  > .row {
			height: $header-mobile-height;
		}
		.brand {
			width:100%;
			max-width: 150px;
			margin-right: auto;
			img {
				width:100%;
				display:block;
	
			}
		}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.menu-trigger-container {
			display: none;
		}
		
		.site-info {
			.shrink & {
				

				> .container > .row {
					height: $header-shrink-height;				
				}
				> .container-fluid > .row {
					height: $header-shrink-height;
				}

			}
			> .container > .row {
				height: $header-desktop-height;
				transition:$transition;
			}
			> .container-fluid > .row {
				height: $header-desktop-height;
				transition:$transition;
			}
			.brand {
				width:100%;
				max-width: $desktop-logo-width;
				transition:$transition;
				position: absolute;
				top: 0;
				margin-right: 0;
				z-index: 3;
				.shrink & {
					max-width:131px;
				}
				img {
					width:100%;
				}
			}
		}
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		

	}
}