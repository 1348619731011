.language-selector {

	max-width: 150px;
	padding-bottom: 7px;
	ul {
		@include list();
		text-align: right;
		margin-right: 30px;
		li {
			display: inline-block;
			& + li {
				&:before {
					font-weight: bold;
					content:'|';
					color:white;
					padding-left:5px;
					padding-right: 10px;
				}
			}
			&.current-lang {
				a {
					color:$primary;
				}
			}
			a {
				font-family: $pFont;
				font-weight: 700;
				color:white;
				text-decoration: none;
				transition:$transition;
				&:hover {
					color:$primary;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-bottom: 7px;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		max-width: 120px;
	
		// position: absolute;
		// right: 0;
		
		ul {
			margin-right: 0;
		}
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}