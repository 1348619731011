.block.custom-image-text {
	position: relative;
	padding-bottom: 53px;

  .custom-image-text--title {
    margin: 0 0 50px 0;
    text-align: right;
  }
	.custom-image-text--title-image {
		position: relative;
		z-index: 2;
	}
	.custom-image-text--image {
		margin-top: -75px;
		width:calc(100% + 44px);
		margin-left:-22px;
	}
	.custom-image-text--text {
		margin-top: 50px;
	}
	.custom-image-text--text p {
		font-weight: 600;
		color:white;
		font-family: $pFont;
		font-size: 20px;
		line-height: 28px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

		.custom-image-text--image {
			margin-top: -75px;
			width:100%;
			margin-left:0;
			img {
				max-width: 115%;
			}
		}

		.custom-image-text--text {
			margin-top: 0;
		}

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		padding-bottom: 100px;
		padding-top: 150px;


    .custom-image-text--title {
      font-size: 80px;
    }

		&.content-left {
			.custom-image-text--title-image {
				position: absolute;
				top: -15%;
				width: 100%;
				height: auto;
				left: -75%;
			}
			.custom-image-text--text {
				text-align: right;
				max-width:100%;
				margin-left: auto;
			}
		}
		&.content-right {
			.custom-image-text--title-image {
				position: absolute;
				top: -5%;
				width: 100%;
				height: auto;
				right: -90%;
			}
			.custom-image-text--image {
				width: 125%;
				margin-left: -25%;
			}
		}

		.custom-image-text--image {
			margin-top: 0px;
		}
		.custom-image-text--text p {
			font-size: 20px;
			line-height: 28px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {



	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.custom-image-text--text p {
			font-size: 27px;
			line-height: 37px;
		}

	}
}
