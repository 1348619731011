.big-text-content {
	padding:0;
	margin:0px 0 100px;

	&.left {
		.big-text-content--big_text.hensa {
			margin-top: 20px;
		}
	}
	&.single {
		.big-text-content--big_text {
			&.hensa {
				margin-top: 10px;
			}
			&.aloja-ligh {
				margin-top: 15px;
			}
		}
		&.left {
			.big-text-content--image {
				margin-top: 25px;
			}
		}
		&.right,
		&.left {
			.big-text-content-primary_content {
				margin-top: 20px;
			}
		}
	}

  .big-text-content--image img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

	.big-text-content--image_col {
		position: relative;
		margin-left:-20px;
		width:calc(100% + 40px);

		&.type-split,
		&.type-single.alignment-left {
			display: none;
		}
		&.mobile-split,
		&.mobile-single {
			display: block;
		}
		.big-text-content--image {
			width:100%;
			display: block;
			padding-bottom:75%;
      position: relative;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}
		}
	}

	.big-text-content--big_text {
		color:white;
		line-height: 1em;
		position: relative;
		z-index: 1;
		&.hensa {
			font-size: 75px;
      text-align: left;
		}
		&.aloja-ligh {
      text-align: center;
			font-size: 75px;
		}
	}

	.big-text-content-primary_content {
		margin-bottom: 30px;
	}
	.big-text-content-secondary_content {
		margin-top: 10px;
	}

	.big-text-content-primary_content,
	.big-text-content-secondary_content {
		color: white;
		font-size: 18px;
		line-height: 1.4em;
		font-weight: 600;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		margin:70px 0;

		&.single {
			&.left {
				.big-text-content-primary_content {
					margin:0;
				}
				.big-text-content--image {
					margin-top: 0;
				}
			}
			.big-text-content--big_text {
				&.aloja-ligh {
					margin-top: 0px;
				}
			}
		}

		.big-text-content--big_text {
			//white-space:nowrap;
			text-align: none;
			&.hensa {
				font-size: 90px;
			}
			&.aloja-ligh {
				font-size: 60px;
			}
		}

		&.big-text-uses-hensa.single {
			margin-top: 80px;
		}

		&.right {
			.big-text-content--big_text {
				direction: rtl;
			}
      .big-text-content--big_text,
			.big-text-content-primary_content,
			.big-text-content-secondary_content {
				padding-left: 40px;
			}
		}
		&.left {
      .big-text-content--big_text,
			.big-text-content-primary_content,
			.big-text-content-secondary_content {
				padding-right: 40px;
			}
			.big-text-content--big_text.hensa {
				margin-top: 10px;
			}
		}

		.big-text-content-primary_content,
		.big-text-content-secondary_content {
			font-size: 22px;
			line-height: 1.4em;
			margin:0;
		}

		.big-text-content--image_col {
			position: relative;
			margin-left: 0;
			width:100%;
			&.type-split,
			&.type-single.alignment-left {
				display: block;
			}
			&.mobile-split,
			&.mobile-single {
				display: none;
			}
			.big-text-content--image {
				padding-bottom: 0;
				position: absolute;
				height: 100%;
				width:100%;
				@include bgImg(center,cover);
			}
		}

	}

	/* =============================================================================
	Large +

			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.big-text-content--big_text {
		&.hensa {
				font-size: 75px;
			}
			&.aloja-ligh {
				font-size: 75px;
			}
		}
	}
}
