.menus.desktop {
	display: none;
	width: auto;
	position: static;
	ul.nav {
		list-style-type: none;
		padding: 0;
		text-align: left;
		margin: 0;
		padding-left:$desktop-logo-width + 20px;

		// padding: 0 163px;
		// text-align: center;

		& > li {
			display: inline-block;
			position: relative;
			&.active {
				> a {
					color:$primary;
				}
			}
			& + li {
				margin-left: 50px;
			}
			
			> a {
				text-decoration: none;
				color: white;
				transition: $transition;
				font-weight: 700;
				font-family: $pFont;
				font-size: 16px;
				position: relative;
				text-transform: uppercase;
				line-height: 1em;
				letter-spacing: 0.4px;
			}
			&:hover {
				> a {
					color:$primary;
				}
				ul.sub-menu {
					opacity: 1;
					visibility: visible;
					transition-delay: 0s,0s;
					transform:translateY(0px);
				}
			}
		}

		ul.sub-menu {
			position: absolute;
			@include list();
			background-color: $lousBlack;
			padding-top: 10px;
			padding-bottom: 20px;
			transition: opacity .2s ease 0s,visibility 0s ease .2s,transform .2s ease 0s,padding .3s ease;
			opacity: 0;
    		visibility: hidden;
    		transform:translateY(-10px);
			&:before {
				content:'';
				background-color: $lousBlack;
				position: absolute;
				top:0;
				left:50%;
				height: 100%;
				width:150vw;
				transform:translateX(-50%);
			}
			li {
				position: relative;
				z-index: 2;
				&.active {
					a {
						background-color: white;
						color:$primary;
					}
				}
				& + li {
					margin-top: 10px;
				}
				a {
					margin-left:-10px;
					text-transform: uppercase;
					white-space:nowrap;
					text-decoration: none;
					color:white;
					font-weight: 600;
					font-family: $pFont;
					padding:5px 10px;
					background-color: transparent;
					transition:$transition;
					&:hover {
						background-color: white;
						color:$primary;
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: block;
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
