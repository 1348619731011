.content--basic-callout {
	height:100%;
	@include bgImg(center, cover);
	text-align: center;
	padding:40px 40px;
	.content--basic-callout--inner {
		display: flex;
		align-items:center;
		flex-wrap:wrap;
		height: 100%;
		justify-content:center;
		width: 100%;
	}

	.content--basic-callout--content {

	}

	.content--basic-callout--title {
		line-height: 1.2em;
		background-color: $lousBlack;
		font-weight: bold;
		color:white;
		padding:5px 20px 0;
		font-size: 75px;
		transform:skew(-3deg);
		display: table;
		margin:0 auto;
		span {
			transform:skew(3deg);
			display: block;
		}
		img {
			position:absolute;
			top:0;
			left:50%;
			transform:translate(-50%, -100%);
			max-width:120px;
		}
	}
	
	.content--basic-callout--description {
		background-color:$lousBlack;
		max-width: 550px;
		margin: 0 auto;
		padding: 16px 12px;
		margin-top: -13px;
		p {
			color:white;
			font-weight: bold;
			font-size: 22px;
			line-height: 1.3em;
		}
	}

	.content--basic-callout--button_wrap {
		position: relative;
		z-index: 2;
		margin-top: -5px;
		a {
			text-decoration: none;
			font-family: $hFont;
			color: #fff;
			font-size: 40px;
			margin-bottom: 20px;
			font-weight: 700;
			padding: 15px 25px;
			letter-spacing: 0;
			line-height: 1em;
			display: inline-block;
			transition:$transition;
			background-color:$primary;
			&:hover {
				background-color: white!important;
				color:$lousBlack!important;
			}
		}
	}

	.content--basic-callout--lous_logo {
		display: block;
		margin:0 auto;
		max-width:135px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding:4vw 40px;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}