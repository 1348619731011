	.slick-prev,
	.slick-next {
	    display: block;
	    position: absolute;
	    background-color:transparent;
	    border:none;
	    color:$grey-med;
	    cursor: pointer;
	    transition:$transition;
	    z-index: 1;
	    font-size:15px;
	    font-family: $hFont;
	    line-height: 1em;
	    border:1px solid #D8D8D8;
    	height: 28px;

    	i {
    		position: absolute;
    	}
	    &:not(.slick-disabled) {
        &:focus,
		    &:hover {
		    	background-color:$primary;
		    	color:white;
		    	border-color:$primary;
		    }
		}
	    &:focus {
	    	outline: none;
	    }
	    &.slick-disabled {
	    	cursor: default;
	    	opacity: 0.3;
	    }
	}

	.slick-prev {
		text-align:right;
		left:10px;
		padding: 3px 25px 2px 60px;
		i {
			left:10px;
		}
	}
	.slick-next {
		text-align: left;
		right:10px;
		padding: 3px 60px 2px 25px;
		i {
			right:10px;
		}
	}

	.slick-dots {
		@include list();
		text-align: center;
		li {
			display: inline-block;
			text-align: center;
			margin-right: 5px;
			&:last-child {
				margin-right: 0;
			}
			&.slick-active {
				.slick-button {
					background-color:$primary;
				}
			}
			.slick-button {
				transition:$transition;
				background-color: transparent;
				border:none;
				outline:none;
				border-radius:50%;
				display: block;
				width:12px;
				height:12px;
				line-height: 1em;
				font-size:0;
				background-color:$grey-med;
				cursor: pointer;
				&:focus {
					outline:none;
				}
				&:hover {
					background-color:darken($grey-med, 15%);
				}
			}
		}
	}
