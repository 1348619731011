.product-list--item {
	margin:20px 0;
	&:hover {
		.product-list--item_image {
			.product-list--item_hover-text {
				opacity: 1;
				transform:translate(-50%,-50%) scale(1);
			}
		}
	}
	a {
		text-decoration: none;
	}
	
	&.quarter {
		.product-list--item_image {
			margin-left:auto;
			margin-right: auto;
			max-width: 275px;
		}		
	}

	.product-list--item_image {
		position: relative;
		.product-list--item_new {
			position: absolute;
			background-color:$red;
			font-family: 'Nexa Rust';
			color:white;
			padding:5px;
			top: -10px;
    		left: -10px;
		}
		.product-list--item_hover-text {
			position: absolute;
			padding:10px;
			background-color: white;
			color:$primary;
			font-family: $hFont;
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%) scale(0.75);
			font-weight: bold;
			font-size: 26px;
			text-align: center;
			opacity: 0;
			transition:$transition;
		}
		
	}

	.product-list--item_title {
		font-size: 17px;
		line-height: 1.1em;
		color:white;
		text-align: center;
		margin: 5px 0;
		font-family: $pFont;
		font-weight: bold;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		&.quarter {
			.product-list--item_image {
				margin-left:0;
				margin-right: 0;
				max-width: none;
			}		
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}