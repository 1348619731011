.component--play-btn-wrap {
	position: absolute;
	top: 50%;
	left:50%;
	transform:translate(-50%,-50%);
	width:64px;
	height: 64px;
	background-color:rgba(black,0.52);
	border:2px solid $primary;
	border-radius: 50%;
	transition:$transition;
	cursor: pointer;
	i {
		position: absolute;
		top: 53%;
		left:54%;
		transform:translate(-50%,-50%);
		color:white;
		font-size: 20px;
	}
	&:hover {
		background-color:$primary;
	}
}