// remove the crappy worpdress mobile admin bar
@media screen and (max-width:675px) {
    #wpadminbar {
    display:none;
  }
  html[lang^='e'] {
    margin-top:0!important;
    .admin-bar .site-header {
      top:0!important;
    }
  }
}

// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: (40px / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: (40px / 2);
  height: auto;
}

@media only screen and (min-width: 768px) {

  .alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    max-width: 1000%;
  }

  .alignwide {
    margin-left: calc(25% - 25vw);
    margin-right: calc(25% - 25vw);
    width: auto;
    max-width: 1000%;
  }

  .alignwide img,
  .alignfull img {
    display: block;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: (40px / 2);
  }
  .alignright {
    float: right;
    margin-left: (40px / 2);
  }
}

/**
 * Gutenberg Blocks
 */

 // youtube

 .wp-block-embed-youtube {

    &:not(.alignfull) {
      @extend .container;
    }

    .wp-block-embed__wrapper {
        @extend .row;
    }
    &:not(.alignfull):not(.alignwide) {
      .wp-block-embed__wrapper {
        max-width:600px;
      }
    }

    &:not(.alignfull):not(.alignwide):not(.alignleft):not(.alignright) {
      .wp-block-embed__wrapper {
        margin:0 auto;
      }
    }

    &.aligncenter {
        .wp-block-embed__wrapper {
            @extend .justify-content-center;
            margin:0 auto;
        }
    }
    &.alignleft {     
      @extend .container;
      float: none;
        .wp-block-embed__wrapper {
            @extend .justify-content-start;
        }
    }
    &.alignright {     
      @extend .container;
      float: none;
        .wp-block-embed__wrapper {
            margin-left: auto;
        }
    }
    &.alignwide,
    &.alignfull {
        .entry-content-asset {
          max-width:none;
        }
    }
    &.alignfull {
      width: 100%;
      max-width:none;
      margin-left:0;
      margin-right:0;
      .wp-block-embed__wrapper {
        margin-left:0;
        margin-right:0;
      }
    }

    .entry-content-asset {
      width:100%;
      max-width:600px;
      position:relative;
      padding-bottom:56.24%;
      iframe {
        position: absolute;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
      }
    }
}