.sidebar .widget,
.apus-sidebar .widget {
  margin: 0 0 40px;
  overflow: hidden;
}
.sidebar .widget .widget-title,
.apus-sidebar .widget .widget-title {
  text-transform: uppercase;
  margin: 0 0 25px;
  font-size: 22px;
  font-weight: 600;
}
.sidebar ul,
.apus-sidebar ul {
  list-style: none;
}
.sidebar .widget-list.widget-products .widget-title,
.apus-sidebar .widget-list.widget-products .widget-title {
  margin: 0;
  background: #212121;
  color: #fff;
  font-size: 14px;
  padding: 15px 30px;
  border: none;
}
.sidebar .widget-list.widget-products .widget-title > span,
.apus-sidebar .widget-list.widget-products .widget-title > span {
  border: none;
  padding: 0;
  margin: 0;
}

.widget_meta > ul,
.widget_archive > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.widget_meta > ul li,
.widget_archive > ul li {
  line-height: 2;
}

.widget_categories select,
.widget_archive select {
  width: 100%;
  padding: 8px;
  color: white;
}
.widget_categories ul,
.widget_archive ul,
.widget_recent_entries ul {
  margin: 0;
  padding: 0;
}
.widget_categories ul li,
.widget_archive ul li 
.widget_recent_entries ul li{
  line-height: 2.2;
}
.widget_categories ul li a,
.widget_archive ul li a 
.widget_recent_entries ul li a{
  display: inline-block;
  color: white;
}
.widget_categories ul li a:hover, .widget_categories ul li a:active,
.widget_archive ul li a:hover, .widget_archive ul li a:active,
.widget_recent_entries ul li a:hover, .widget_recent_entries ul li a:active {
  color: $primary;
}
.widget_categories ul li a:before,
.widget_archive ul li a:before,
.widget_recent_entries ul li a:before {
  color: $primary;
  margin-right: 15px;
  display: inline-block;
  font-size:10px;
  vertical-align:2px;
}
.rtl .widget_categories ul li a:before, .rtl
.widget_archive ul li a:before,
.rtl .widget_recent_entries ul li a:before {
  margin-left: 15px;
  margin-right: inherit;
}

.widget_pages ul {
  list-style: none;
}
.widget_pages ul li {
  line-height: 2;
}
.widget_pages > ul {
  padding: 0;
  margin: 0;
}

.textwidget select {
  padding: 8px;
  max-width: 100%;
  color: white;
}


.sidebar {
	//margin-top:100px;
	ul {
		padding:0;
		li {
			margin-bottom: 10px;
			border-bottom: 1px solid rgba(255,255,255,0.1);
			padding-bottom: 10px;
		}
	}
	.widget li a {
		text-decoration: none;
		transition:$transition;
		color:$primary;
		font-weight: 500;
		font-family: $pFont;
		font-weight: bold;
		text-decoration: underline;
		&:hover {
			color:$secondary;
		}
	}

	 .widget-title, .widget .widgettitle, .widget .widget-heading, h3 {
		font-size: 22px;
		font-weight: 600;
	    position: relative;
	    font-family: $hFont;
	    position: relative;
	    padding: 0 0 18px;
		margin: 0 0 30px;
		text-transform:uppercase;	
		&:before,
		&:after {
			content: "";
			background: #f2f2f2;
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
		&:after {
			background: $primary;
			width: 60px;
			height: 1px;
			z-index: 2;
		}
	}

	.widget_categories ul li a, .widget_archive ul li a {
	    display: inline-block;
	    color: #707070;
	}

	.widget_categories ul li, .widget_archive ul li {
	    line-height: 2.2;
	}

	.widget_categories ul, .widget_archive ul {
	    margin: 0;
	    padding: 0;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		margin-top: 0;


		> ul {
			padding-left:40px;
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}