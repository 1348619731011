.btn {
	display: inline-block;
	text-decoration: none;
	text-align: center;
	position: relative;
	outline:none;
	border:none;
	appearance:none;
	text-transform: uppercase;
	cursor: pointer;
	transition:background-color 0.3s ease, color 0.3s ease;
	letter-spacing: 0.8px;
	font-family: $hFont;
	font-weight: normal;
	font-size: 20px;

	padding:9px 35px;

	&.primary {
		background-color:$primary;
	}
	&.secondary {
		background-color:$secondary;
	}
	&.primary,
	&.secondary {
		padding:10px 35px 8px;
		color:white;
    &:focus,
		&:hover {
			background-color:white!important;
			color:$lousBlack;
		}
	}

	&.white:not(.simple) {
		background-color: white;
		color:$primary;
		&.hover-state_orange {

      &:focus,
			&:hover {
				background-color:$grey-med;
				color:white;
			}
		}
		&.hover-state_grey {

      &:focus,
			&:hover {
				background-color:$primary;
				color:white;
			}
		}
	}

	&.disabled {
		color:$grey-med;
		cursor:default;

    &:focus,
		&:hover {
			color:$grey-med;
			&:after {
				transform:translate(0px,-50%)
			}
		}
		&:after {
			color:$grey-med;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}

}


