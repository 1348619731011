.post-item {
	height: 100%;
	padding-bottom:40px;
	article {
		background-color: $lousBlack;
		height: 100%;
		position: relative;
		padding-bottom:55px;
	}

	&:hover {
		.image-wrap {
			img {
				transform:scale(1.05);
			}
		}
	}

	.image-wrap {
		display: block;
		overflow: hidden;
		img {
			transition:$transition;
			transform:scale(1);
			display: block;
		}
	}

	.inner {
		padding:20px 20px 20px 20px;
		.category-list {
			.post-categories {
				@include list();
				li {
					a {
						color:white;
						text-decoration: none;
						font-weight: bold;
						font-size: 18px;
						padding:5px;
						transition:$transition;
						&:hover {
							background-color:white;
							color:$primary;
						}
					}
				}
			}
		}
		h4 {
			margin:7px 0 0px 0;
			a {
				color:$primary;
				text-decoration: none;
				font-family: $pFont;
				font-weight: bold;
				font-size: 20px;
				line-height: 1.3em;
				letter-spacing: 0.5px;
				display: block;
			}
		}
		.description {
			color:white;
			font-size: 18px;
			line-height: 1.6em;
			padding:20px 0;
		}
		.btn-wrap {
			position:absolute;
			bottom:20px;
			.btn {
				font-family: $pFont;
				font-weight: bold;
				letter-spacing: normal;
				padding-left:20px;
				padding-right: 20px;
				&:hover {
					background-color: $primary!important;
					color:white!important;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}