.o-where-to-buy{
	background: white;
	text-align: center;
	padding-top: 65px;

	.container{
		max-width :1200px;
	}
}
.o-where-to-buy--title{
	margin: 0 0 25px;
}
.o-where-to-buy--title--inner{
	display: inline-block;
	position: relative;
	position: relative;
	padding-top: 15px;
	padding-bottom: 0px;

	&:before{
		content:'';
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: -25px;
		right: -25px;
		background: url(../images/background.svg) no-repeat center center;
		background-size: 100% 100%;
		z-index: 1;

		
	}

	span{
		position: relative;
		z-index: 2;
	}
}
.o-where-to-buy--header{
	max-width: 550px;
	margin: 0 auto;
}
.o-where-to-buy--list{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 50px;
}
.o-where-to-buy--single{
	padding: 5px 15px;
	text-align: center;
	min-width: calc(100% / 3);
	width: calc(100% / 3);
}
.o-where-to-buy--link{
	transition: all .3s ease;
	overflow: 1;

	&:hover,
	&:focus{
		opacity: 0.6;
	}
}





/* =============================================================================
Medium +
========================================================================== */
@include media-breakpoint-up(md) {


	.o-where-to-buy--single{
		min-width: calc(100% / 6);
		width: auto;
	}
} 