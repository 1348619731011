.page-template-products,
.tax-product-category {
	
	.product--description {
		margin:0 0 60px 0;
		.product--description_content {
			color:white;
			font-weight: 600;
			font-size: 18px;
		}
	}
	.product--description-button{
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 30px;
		color: #FFFFFF;
		padding: 15px 18px 10px;
	}
	#loading-wrap {
		margin-bottom:75px;
		@include loading();
	}
	.product-filters {
		&.mobile {
			li {
				a {
					html[lang='fr-CA'] & {
						font-size: 9px;
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.product--description {
			margin:0 0 60px 0;
			.product--description_content {
				font-size: 25px;
				line-height: 1.5em;
			}
		}

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.product-filters {
			&.desktop {
				display: flex;
				li {
					&.filter-title {
						display: block;
					}
					a {
						html[lang='fr-CA'] & {
							font-size: 11px;
						}
						font-size: 16px;
					}
				}
			}
			&.mobile {
				display: none;
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
