.search-form-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba($grey-med,0.75);
	z-index: 101;
	text-align: center;
	
	.search-form-overlay--close {
		color:white;
		position: absolute;
		right: 30px;
		top: 30px;
		font-size: 58px;
		transition:$transition;
		cursor: pointer;
		&:hover {
			color:$secondary;
		}
	}

	.row {
		height: 100vh;
	}

	input[type="search"] {
	    background-color: transparent;
	    border: none;
	    border-bottom: 3px solid white;
	    width: 66%;
	    font-size: 24px;
	    color: white;
	    font-family: $pFont;
	    &::placeholder {
			color:rgba(white,0.4);
			font-style: italic;
			font-family: $hFont;
			font-weight: 300;
			font-size: 24px;
	    }
	    &:focus {
	    	outline:none;
	    }
	}

	button.search-field {
		border: none;
		background-color: transparent;
		color: white;
		font-size: 20px;

		cursor: pointer;
		&:focus {
			outline:none;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		input[type="search"] {
			font-size: 50px;
		    &::placeholder {
				font-size: 35px;
			}
		}
		button.search-field {
			font-size: 31px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}