.block.content-media {
	padding:80px 0;
	background: {
		size:cover;
		position:center;
	}
	position: relative;
	&.content-media--dark-overlay {
		&:before {
			content:'';
			position:absolute;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color:rgba(0,0,0,0.6);
		}
	}
	&.content-media--theme_dark {
		background-color: transparent;
		.content-media--sub-title {
			color:$primary;
		}
		.content-media--title,
		.content-media--description {
			color:white;
			p {
				color:white;
			}
		}
	}
	&.content-media--theme_light {
		background-color: white;
		.content-media--sub-title {
			color:$primary;
		}
		.content-media--title,
		.content-media--description {
			color:$lousBlack;
			p {
				color:$lousBlack;
			}
		}
	}

	.content-media--content {
		max-width: 410px;
	}
	.content-media--sub-title {
		margin:0 0 15px 0;
	}
	.content-media--title {
		margin:0 0 10px 0;
		font-weight: normal;
	}
	.content-media--description {
		font-size: 16px;
		line-height: 1.5em;
	}
	.content-media--link_wrap {
		margin-top: 20px;
	}
	.content-media--media_wrap {
		margin-bottom: 20px;
	}
	.content-media--video-poster {
		width:100%;
		position: relative;
		padding-bottom:60%;
		background: {
			size:cover;
			position:center;
		}
	}
	.content-media--image_wrap {}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.content-media--media_wrap {
			margin-bottom: 0px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	
	}
}