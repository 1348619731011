	@include bodyTypography();

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family:$hFont;
		line-height: 1em;
		text-transform: uppercase;
		& + p {
			margin-top:10px;
		}
		&.montserrat {
			font-family:$pFont;
		}
	}

		h1 {
			font-size:77px;
			font-weight: 600;
			color:white;
		}
		h2{
			font-size:55px;
			font-weight: 600;
			color:white;
		}
		h3 {
			font-size:45px;
			font-weight: normal;
			color:white;
		}
		h4 {
			font-size: 30px;
			font-weight: normal;
			color:white;
		}
		h5 {
			font-size:20px;
			font-weight: bold;
			color:white;
			font-family: $pFont;
		}
		h6 {
			font-size:18px;
			color:white;
			font-family: $pFont;
		}

	p {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom:0;
		}
		a {
			text-decoration: none;
			transition:$transition;
			&:hover {
				color:white;
			}
		}
	}

	.nexa-rust {
		font-family: 'Nexa Rust';
	}
	.hensa {

		font-family: 'Organika-Script';
	}
	.aloja-ligh {
		font-family: 'Organika-Sans';
	}

	strong {
		font-weight: 700;
	}

	.small-heading {
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1.1px;
		text-transform: uppercase;
		color: white;
	}

	table {
		border:none;
		width:100%;
		display: block;
		margin-left:0!important;
		margin-right:0!important;
		border-collapse: collapse;
		tfoot,
		tbody {
			border:1px solid white;
		}
		tfoot {
			background-color: white;
		}
		thead {
			background-color: $primary;
			color:white;
		}
		td,
		th {
			padding:15px;
			width:33.33%;
			border-bottom:1px solid white;
			font-size: 14px;
			line-height: 21px;
			letter-spacing: 1.4875px;
			& + td {
				border-left:1px solid white;
			}
			a {
				color:$primary;
			}
		}
		tr:last-child {
			td {
				border-bottom: 0;
			}
		}
	}


/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		h1 {
			font-size:120px;
		}
		h2{
			font-size:60px;
		}
		h3 {
			font-size:50px;
		}
		h4 {
			font-size: 35px;
		}
		h5 {
			font-size:24px;
		}
		h6 {
			font-size:20px;
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}