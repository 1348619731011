.block.form {
	padding-top: 30px;

	&.has-sidebar {
		.form--description,
		.form--shortcode {
			max-width: none;
		}
	}
	.form--description {
		color:white;
		font-size: 32px;
		line-height: 1.3em;
		max-width: 900px;
		font-weight: 600;
		margin-bottom: 50px;
	}
	.form--shortcode {
		max-width:750px;
	}

	.form--sidebar {
		color:white;
		font-size: 28px !important;
		line-height: 1.5em;

		.form--sidebar-content {
			i {
				margin-right: 5px;
				vertical-align: -3px;
			}
			a[href^="tel:"] {
				color:white;
				font-weight: inherit;
				text-decoration: none;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
