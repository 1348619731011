.product-callout {
	position: relative;
  padding: 0;
	//padding:0px 0 30px;
	margin:0px auto;
  max-width: 1440px;
  overflow: hidden;

  .product-callout--wrapper{
    margin:0 auto;
  }

  .product-callout--content-wrapper{
    padding: 45px;
  }

	&.product-callout--align-left {
		.product-callout--background-image {
			left:0;
		}
    .product-callout--image-wrapper {
      &:before{
        left: 0;
      }
    }
	}
	&.product-callout--align-right {
		.product-callout--background-image {
			right: 0;
		}
    .product-callout--image-wrapper {
      &:before{
        right: 0;
      }
    }
	}

  .product-callout--image-wrapper {
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      background: var(--pc-background);
      z-index: 1;
      width: 100vw;
    }
  }

	.product-callout--container {}
	.product-callout--row {}
	.product-callout--product-image {}
	.product-callout--content {}
	.product-callout--content_title {
		margin: 0 0 20px 0;
		color:white;
		letter-spacing: 0.125em;
		font-weight: normal;
		font-size: 32px;
		line-height: 1.2em;
	}
	.product-callout--content_description {
		color: white;
		font-size: 19px;
		line-height: 1.3em;
		font-weight: 600;
		margin: 0 0 30px 0;
	}
	.product-callout--content_link {
		a {
			font-size: 35px;
			font-weight: bold;
			padding:18px 30px;
			&:hover {
				background-color: $lousBlack;
				color: white!important; // overrides our inline custom css rule...
			}
		}
	}
  .product-callout--product-image{
    position: relative;
    z-index: 20;
  }

	.product-callout--watch_video {
		padding:0 20px;
		margin-top: 10px;
		a {
			display: inline-block;
			transition: all .3s ease;
			padding: 14px 40px;
			background-color:white;
			text-decoration: none;
			text-align: center;
			font-family: $hFont;
			font-weight: bold;
			font-size: 35px;
			font-weight: 700;
			padding: 18px 30px;
			&:hover {
				background-color: $lousBlack;
				color:white!important; // override inline style...
			}
		}
	}

	.mobile-background-image {
		@include bgImg('center', cover);
    z-index: 10;
    background-image:none!important;
    margin: 0;
    padding: 100px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
  }

  /* =============================================================================
  Large +
  ========================================================================== */
  @include media-breakpoint-up(lg) {
  margin:20px auto;


    .product-callout--content-wrapper{
      padding:85px 60px;
      min-height: 700px;
    }
		.product-callout--content_title {
			font-size: 43px;
		}

		.product-callout--background-image {
			height: 100%;
      width: calc( 100% - 200px );
      z-index: 10;
			position:absolute;
			display: block;
			top: 0;
			@include bgImg(center,cover);
		}

    &.product-callout--align-left {
      .product-callout--content-wrapper{
        padding-left: 100px;
      }
      .mobile-background-image {
        padding-left: 60px;
      }
    }
    &.product-callout--align-right {

      .product-callout--content-wrapper{
        padding-right: 100px;
      }
      .mobile-background-imagee {
        padding-right: 60px;
      }
    }


		.product-callout--content_description {
			font-size: 28px;
		}


		.product-callout--watch_video {
			margin-top: 0;
			padding:0;
			position: absolute;
			left: 50%;
			bottom: -30px;
			width: auto;
			transform: translateX(-50%);
			z-index: 2;
			a {
				display: block;
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
