footer.footer-content {
	background-color: $lousBlack;
	padding-bottom:50px;
	p,
	a,
	td {
		font-family: $pFont;
	}

	.socket {
		a {
			opacity: 0.8;
			color: white;
			text-decoration: none;
			transition: $transition;
			&:hover {
				color: $primary;
			}
		}
	}

	.main-footer {
		padding-top: 53px;
		padding-bottom: 21px;
		background-color: $lousBlack;
		a {
			color: $primary;
		}

		
	}

	.footer--logo-wrap {
		max-width: 100px;
		margin-bottom: 50px;
		margin-top: -20px;
		img {
			width: 100%;
			display: block;
		}
	}

	.footer--menus-wrap {
		h3 {
			font-size: 17px;
			font-family: $pFont;
			text-transform: uppercase;
			color: white;
			margin: 0 0 12px 0;
			font-weight: 600;
			line-height: 1.4em;
		}

		ul.nav {
			@include list();
			margin-bottom: 25px;
			li {
				margin-bottom: 12px;
				a {
					text-decoration: none;
					font-family: $pFont;
					color: white;
					font-size: 15px;
					letter-spacing: -0.0283632px;
					transition:$transition;
					opacity: 0.8;
					&:hover {
						color:$primary;
					}
				}
			}
		}
	}
	

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-bottom: 0;
				
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.footer--logo-wrap {
			max-width: 210px;
			margin-bottom: 0;
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
