.recommended-product {

	padding:65px 0;

	
	.recommended-product__title {
		color:white;
		font-size: 70px;
		display: block;
		text-align: center;
		margin:0 0 60px 0;
	}

	.recommended-product__short-description {
		color:white;
		font-size: 19px;
		font-weight: 600;
		line-height: 1.5em;
	}

	.recommended-product__button-wrap {
		a {
			padding:20px 25px;
			font-size: 20px;
			letter-spacing: 0;
			font-weight: bold;
			font-size: 45px;
			&:hover {
				background-color:$lousBlack;
				color:white!important;
			}
		}
	}

	.recommended-product__image-wrap {

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.recommended-product__title {
			font-size: 100px;
		}

		.recommended-product__short-description {
			font-size: 23px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}