ul.social-icons {
	@include list();
	li {
		display: block;
		padding-left:25px;
		position: relative;
		margin-bottom: 5px;
		a {
			footer.footer-content .main-footer & {
				text-decoration: none;
				color:white;
				transition:color 0.3s ease;
				opacity: 0.8;
				&:hover {
					color:$primary;
				}
			}
			i {
				position:absolute;
				top:55%;
				left:0%;
				transform:translateY(-50%);
				font-size: 15px;
			}
			&:hover {
				color:$secondary;
			}
		}
	}
}